.box-background {
    background-color: #FFFFFF;
  max-width: 1600px !important;
    min-height: 300px;
    margin-top: 25px;
    border-radius: 20px;
    margin-bottom: 25px;
    padding-bottom: 30px;
}
.background {
    background-color: #D8D6DF;
}

.progress-bar {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    color: #fff !important;
    text-align: center !important;
    white-space: nowrap !important;
    background-color: #059669 !important;
    transition: width 0.6s ease !important;
    border-radius: 20px !important;
}

.progress {
    display: flex !important;
    height: 1rem !important;
    overflow: hidden !important;
    font-size: .75rem !important;
    background-color: #e9ecef !important;
    border-radius: 20px !important;
    margin-left: 60px !important;
    margin-right: 60px !important;
    margin-top: -10px !important;
}

.text-progress {
    margin-left: 60px !important;
    margin-right: 60px !important;
}

.text-progressRight {
    position: relative;
    float: right;
    color: #F45197;
}
.text-center {
    text-align: center;
}
.text-centerColor {
    text-align: center;
    color: #F45197;
}

.text-pink {
    color: #F45197 !important;
  
} 

.loading-size {
    font-size:  200px !important;
}



.box-number-people {
    text-align: center;
    padding-top:  30px;
    min-width: 330px;
    min-height: 300px;
    background-color: #FFFFFF;
    margin-top: 27px;
    border-radius: 20px;

}

.table-responsive>.table-bordered {
    border: aliceblue !important;
}
.people {
    color: #F45197;
    font-size: 30px;
}
.dropdown{
    padding-top: 10px;
    padding-left: 30px;
}
.gamification {
    margin-top: 20px;
    width: 420px;
    margin-left: -20px;
    height: 150px;
    background-color: #FFFFFF;
    margin-bottom: 50px;
    border-radius: 20px;
    padding-top: 20px;
    text-align: center;
}
.active-season {
    margin-top: 20px;
    width: 420px;
    margin-left: 5px;
    height: 150px;
    background-color: #FFFFFF;
    margin-bottom: 50px;
    border-radius: 20px;
    padding-top: 20px;
    text-align: center;
}


.download-table-xls-button {
  border-color: #F45197;
  border-radius: 10px;
  height: 40px;
  font-size: 18px;
  font-weight: normal;
  color: #4F4F4F;
  padding-top: 5px;
  float: right;
  margin-bottom: 20px;
}